import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SignOutService } from '@pushdr/patientapp/common/utils';
import { NhsSignInOIDCService } from '../../services/nhs-signin/nhs-sign-in.service';

@Component({
  selector: 'pushdr-nhs-verification-info',
  styleUrls: ['./nhs-verification-info.component.scss'],
  templateUrl: './nhs-verification-info.component.html',
})
export class NhsVerificationInfoComponent implements OnDestroy {
  redirectDelayTimer$: Observable<number>;
  private ngUnsubscribe$ = new Subject<void>();
  redirecting = false;

  constructor(
    private router: Router,
    private signoutService: SignOutService,
    private nhsSignInOIDCService: NhsSignInOIDCService
  ) {}

  ngOnDestroy() {
    this.redirecting = false;
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  goBackToSignUp() {
    this.router.navigate(['register', 'signup']);
  }

  signUpWithNHS() {
    this.redirecting = true;
    const goldenRatio = 1618;
    this.redirectDelayTimer$ = timer(goldenRatio).pipe(takeUntil(this.ngUnsubscribe$));

    this.redirectDelayTimer$.subscribe(() => {
      this.signoutService.flushUser().subscribe(() => {
        this.nhsSignInOIDCService.getAuthCode();
      });
    });
  }
}
