import { Injectable } from '@angular/core';
import { RestHttpHeaderService } from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  AccountProfileService,
  resolveTheCustomersAccountProfile,
} from '@pushdr/patientapp/common/utils';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  constructor(
    private headers: RestHttpHeaderService,
    private envProxy: EnvironmentProxyService,
    private accountProfile: AccountProfileService
  ) {}

  getAppConfig() {
    return Promise.all([
      this.redirectToSigninIfNoHeadersAndSafeToDo(this.headers, this.envProxy),
      resolveTheCustomersAccountProfile(this.accountProfile),
    ]);
  }

  redirectToSigninIfNoHeadersAndSafeToDo(
    header: RestHttpHeaderService,
    envProxy: EnvironmentProxyService
  ) {
    return new Promise(resolve => {
      if (!header.headers()) {
        const pathname = window.location.pathname;
        const isRefreshSafe = [
          '/register',
          '/patient',
          '/verification',
          '/terms',
          '/surgery-preselect',
          '/what-is-nhs-login',
          '/nhs-verification',
          '/auth',
        ].every(url => pathname.indexOf(url) === -1);
        if (isRefreshSafe) {
          history.replaceState({}, 'Log in', envProxy.environment.patient.funnel.signin);
          window.location.href = envProxy.environment.patient.funnel.signin;
          resolve(window.location.href);
        }
      }
      resolve(window.location.href);
    });
  }
}
