import { Inject, Injectable } from '@angular/core';
import { StorageService, TokenService, WINDOW, ExtendedWindow } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import * as Sentry from '@sentry/browser';
import { forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { EncryptedUserService } from '../encrypted-user/encrypted-user.service';

@Injectable({
  providedIn: 'root',
})
export class SignOutService {
  constructor(
    private modal: ModalService,
    private store: StorageService,
    private token: TokenService,
    private analytics: AnalyticsService,
    private envProxy: EnvironmentProxyService,
    private userService: EncryptedUserService,
    private apiNHSService: ApiNHSPatientService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  signout(redirect = false) {
    this.analytics.trackEvent({ action: 'logout' });
    this.modal.showLoader({
      bottomText: 'Going to log in page',
    });
    return this.flushUser().pipe(
      tap(() => {
        const path = this.getPath(redirect);
        this.window.location.href = path;
      })
    );
  }

  flushUser(fullFlush = false) {
    return this.flushBackend().pipe(
      tap(() => this.flushFrontEnd(fullFlush)),
      catchError(() => {
        this.flushFrontEnd(fullFlush);
        return of(null);
      })
    );
  }

  flushFrontEnd(fullFlush = false) {
    if (fullFlush) {
      this.analytics.setUser({ id: '' });
      Sentry.configureScope(scope => {
        scope.clear();
      });
    }
    this.store.deleteSessionStorage();
    this.token.delete();
    this.userService.reset();
  }

  goToSignIn() {
    this.modal.showLoader({
      bottomText: 'Going to log in page',
    });
    const path = this.getPath();
    this.window.location.href = path;
  }

  private getPath(isRedirect: boolean = false) {
    let nextPath = this.envProxy.environment.patient.funnel.signin;
    if (isRedirect) {
      nextPath += '?returnUrl=' + encodeURIComponent(this.window.location.href);
    }
    return nextPath;
  }

  private flushBackend() {
    const currentToken: string = this.token.get();
    const invalidateTokenObs$ = this.logoutApi(currentToken);

    return forkJoin([invalidateTokenObs$]);
  }

  private logoutApi(token: string) {
    const logoutAPFeatureToggle = false;
    if (token && logoutAPFeatureToggle) {
      return this.apiNHSService.authentication.invalidateToken(token);
    } else {
      return of(null);
    }
  }
}
