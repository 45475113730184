import { DOCUMENT, Location } from '@angular/common';
import { Component, HostBinding, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import {
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EncryptedUser, genderIntToString, PDServerError } from '@pushdr/common/types';
import { BrowserDetectorService, SystemCheckService, TokenService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { FOOTER_TYPE, HEADER_TYPE } from '@pushdr/patientapp/common/components';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ActionRequestService, InviteService } from '@pushdr/patientapp/common/services';
import {
  AccountProfileService,
  EncryptedUserService,
  SignOutService,
} from '@pushdr/patientapp/common/utils';
import * as Sentry from '@sentry/browser';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { FlowStateEvent, FlowStateService } from './services/funnel-flow/flow-state.service';
import { SeoService } from './services/seo/seo.service';
import { ModalService } from '@pushdr/common/overlay';
import { RoutingLoadingIndicator } from './routing-loading-indicator.service';

declare const window: any;
@Component({
  selector: 'pushdr-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  hideDevLinks: boolean;
  routerInterceptSub: Subscription = null;
  isIE10 = false;

  @HostBinding('attr.pdr-version')
  get pdrVersion(): string {
    return this.envProxy.environment.version;
  }

  get isBookingUrl() {
    const urlPath = this.location.path();
    return /^\/(booking|consent)/.test(urlPath);
  }

  constructor(
    private systemCheck: SystemCheckService,
    private flowState: FlowStateService,
    private userService: EncryptedUserService,
    private analytics: AnalyticsService,
    private modal: ModalService,
    private errors: RestErrorParserService,
    private restHeaderService: RestHttpHeaderService,
    private router: Router,
    public location: Location,
    public profile: AccountProfileService,
    private signoutService: SignOutService,
    private invite: InviteService,
    private actionRequest: ActionRequestService,
    private api: ApiNHSPatientService,
    private seo: SeoService,
    private envProxy: EnvironmentProxyService,
    private routingLoadingIndicator: RoutingLoadingIndicator,
    private browserDetectorService: BrowserDetectorService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.checkForMaintenanceFeatureFlag();
    this.isIE10 = this.browserDetectorService.isIE10();
    this.hideDevLinks = !this.envProxy.environment.testingMode;
    this.systemCheck.initialise();
    this.analytics.initialise('patientapp-funnel');
    this.setUser();
    this.setupDefaultErrorCodeActions();
    this.updateHeadersWhenUserChanges();
    this.scrollToTopOnNavigationChange();
    this.setSEOMetaSettingsOnNavigation();
    this.resolveInvite(); // TODO: invite should be deprecated when switch to action request is complete
    this.resolveActionRequest();
    this.routerInterceptSub = this.router.events.subscribe((event: Event) => {
      this.routingLoadingIndicator.interceptRouterEvent(event);
    });
  }

  ngOnDestroy() {
    if (this.routerInterceptSub) {
      this.routerInterceptSub.unsubscribe();
    }
  }

  checkForMaintenanceFeatureFlag() {
    // Replaces 'maintenance' optimizely flag
    // TODO: Add proper maintenance flag toggling from API
    const maintenanceEnabled = false;
    if (maintenanceEnabled) {
      this.router.navigate(['/maintenance']);
    }
  }

  @HostListener('mousemove')
  onClick() {
    this.document.body.classList.add('accessibility-mouse');
    this.document.body.classList.remove('accessibility-keyboard');
  }

  @HostListener('keydown', ['$event'])
  onKeyPress() {
    if (this.document.activeElement.tagName !== 'TEXTAREA') {
      this.document.body.classList.add('accessibility-keyboard');
      this.document.body.classList.remove('accessibility-mouse');
    }
  }

  private resolveActionRequest() {
    this.actionRequest.getActionRequest$().subscribe({
      error: (err: PDServerError) => {
        this.showInviteExpiredModal(err);
      },
    });
  }

  private resolveInvite() {
    this.invite.getInvite().subscribe(
      res => {
        console.log('next invite code: ', res ? res.code : '----');
      },
      (err: PDServerError) => {
        this.showInviteExpiredModal(err);
      }
    );
  }

  private showInviteExpiredModal(err) {
    const errMessage = this.api.errorMessages.invitations.commonInviteErrors(err);
    this.invite.flushInviteCookie();
    this.modal
      .acknowledge(errMessage.header, errMessage.message)
      .pipe(take(1))
      .subscribe(d => (window.location = window.location.pathname));
  }

  getHeaderType() {
    if (this.isBookingUrl) {
      return HEADER_TYPE.PDR_DEFAULT;
    } else {
      return FOOTER_TYPE.COMMON_FUNNEL;
    }
  }

  getFooterType() {
    if (this.isBookingUrl) {
      return FOOTER_TYPE.NONE;
    } else {
      return FOOTER_TYPE.COMMON_FUNNEL;
    }
  }

  /**
   * Setup the tracking of user
   */
  setUser() {
    this.profile.externalId$.subscribe((id: string) => {
      if (id) {
        this.analytics.setUser({ id });
        Sentry.configureScope(scope => {
          scope.setUser({ id });
        });
      }
    });

    // flow manager changes to state trigger this event
    this.flowState.state$.subscribe((event: FlowStateEvent) => {
      const userProps: any = {};

      // if a delta for the patient is detected
      if (event.delta.patient) {
        userProps.gender = genderIntToString(event.state.patient.Gender);
        userProps.age = moment().diff(event.state.patient.DOB, 'years');
        const address1 = event.state.patient.Addresses.shift();
        if (address1) {
          userProps.postcodeArea = address1.PostCode.slice(0, 3);
        }
        if (event.state.registration.SurgeryOds) {
          userProps.surgeryOds = event.state.registration.SurgeryOds;
        }
      }

      if (event.delta.registration) {
        userProps.socialSignInUsed = event.state.registration.SocialSignIn;
      }
    });
  }

  /**
   * subscribe to the user in login service which is null or a value when logging in / out (or refreshing)
   */
  updateHeadersWhenUserChanges() {
    this.userService.user$.subscribe((user: EncryptedUser) => {
      this.restHeaderService.user = user;
      if (user) {
        this.api.customer.getCustomer().subscribe(customer => {
          this.profile.update(customer.AccountProfiles);
        });
      }
    });
  }

  /**
   * Default error handling for 401 and 403 - special modal to sign in again
   */
  setupDefaultErrorCodeActions() {
    this.errors.errors$.subscribe((error: PDServerError) => {
      switch (error.status) {
        case 401:
        case 403:
          setTimeout(() => {
            if (location.pathname.match(/sign(in|up)/)) {
              this.signoutService.flushFrontEnd();
            } else {
              // TODO: btn text should be 'Sign in'
              this.modal
                .error(
                  'Your session seems to have expired. Please log in again and you can continue.'
                )
                .pipe(take(1))
                .subscribe(d => {
                  this.signoutService.flushFrontEnd(false);
                  this.signoutService.goToSignIn();
                });
            }
          }, 500);
      }
    });
  }

  scrollToTopOnNavigationChange() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  setSEOMetaSettingsOnNavigation() {
    this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe((evt: NavigationEnd) => {
        switch (evt.urlAfterRedirects) {
          case '/register/signup':
            this.seo.unblockRobots();
            this.seo.setTitle('Create your Push Doctor account');
            break;
          case '/register/login':
            this.seo.unblockRobots();
            this.seo.setTitle('Log in to Push Doctor');
            break;
          case '/register/getcode':
            this.seo.unblockRobots();
            this.seo.setTitle('Reset your password | Push Doctor');
            break;
          default:
            this.seo.resetTitle();
            this.seo.blockRobots();
        }
      });
  }
}
