<ng-container *ngIf="marketingPolicy?.CallToAction; else loading">
  <section class="c-form-part" trackView="sign up">
    <h1 class="c-form-part__title c-form-part__title--long" pdFocus="true">
      Register your details
      <span class="u-sr-only"> contains a form with email input and a checkbox </span>
    </h1>
    <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
      <div
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="f.invalid && f.submitted">
        <label>
          Email address
          <input
            tabindex="0"
            [(ngModel)]="Email"
            #email="ngModel"
            name="Email"
            pattern="[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*"
            class="c-form-part__field"
            id="ipt-email"
            required
            aria-required="true"
            type="email"
            autocomplete="email" />
        </label>
        <p
          class="c-form-part__error-message"
          id="err-msg-pattern"
          *ngIf="email?.errors?.pattern"
          role="alert">
          Please use a valid email format e.g. name@domain.com
        </p>
        <p
          class="c-form-part__error-message"
          id="err-msg-required"
          *ngIf="email?.errors?.required"
          role="alert">
          An email address is required to sign up.
        </p>
      </div>
      <ng-container *ngTemplateOutlet="marketingcta"></ng-container>
      <ng-container *ngTemplateOutlet="privacycta"></ng-container>
      <button
        class="c-btn c-btn--primary c-form-part__btn"
        value="Sign up"
        trackClick="email"
        aria-label="Click next to continue">
        Continue
      </button>
    </form>

    <div *ngIf="nhsSignInFeature" class="separator u-mb24@xs">OR</div>

    <ng-container *ngTemplateOutlet="nhslogin"></ng-container>
    <ng-container *ngTemplateOutlet="signin"></ng-container>
  </section>
</ng-container>

<ng-template #loading>
  <pushdr-spinner-loader message="Loading"></pushdr-spinner-loader>
</ng-template>

<ng-template #signin>
  <p tabindex="0" class="u-text-center">
    <strong>Already have an account?</strong>
    <a [routerLink]="['/register/login']" trackClick="login" aria-label="Click to log in">
      Log in
    </a>
  </p>
</ng-template>

<ng-template #marketingcta>
  <p tabindex="0" class="u-mb16@xs c-form-part__field-tip" id="offers-info">
    {{ marketingPolicy?.CallToAction }}
  </p>
  <div class="w-full">
    <input
      tabindex="0"
      class="mr-3"
      type="checkbox"
      id="checkbox_1"
      name="marketingPolicy"
      [(ngModel)]="marketingPolicy.Accepted"
      trackClick="email me"
      aria-describedby="offers-info" />
    <label class="" for="checkbox_1"> Yes, please email me </label>
  </div>
</ng-template>

<ng-template #privacycta>
  <p tabindex="0" class="u-mt24@xs u-font-style-small">
    By creating your account you confirm you have read and understood the
    <button
      type="button"
      class="c-btn-anchor"
      (click)="readPolicy(privacyPolicy)"
      trackClick="privacy policy">
      Privacy &amp; Cookie Policy
    </button>
    and agreed to the
    <button
      type="button"
      class="c-btn-anchor"
      (click)="readPolicy(termsPolicy)"
      trackClick="privacy policy">
      Terms and conditions
    </button>
    .
  </p>
</ng-template>

<ng-template #nhslogin>
  <div *ngIf="nhsSignInFeature" class="c-form-part__margin-wrap">
    <img
      title="Register using NHS login"
      alt="register-with-nhs-login"
      src="/assets/nhs-assets/nhs_register_with_original.svg"
      class="btn-nhs"
      tabindex="0"
      (click)="signUpWithNHS()" />
    <a routerLink="../../what-is-nhs-login" data-cy="what-is-nhs-login">
      <p class="what-is-nhs-login">What is NHS Login?</p>
    </a>
  </div>
</ng-template>
