import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { EnvironmentProxyService } from '@pushdr/environment';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  initialised = false;

  constructor(private envProxy: EnvironmentProxyService, private msgBus: AnalyticsBusService) {
    if (!this.initialised && this.envProxy.environment.production) {
      Sentry.init({
        dsn: 'https://e7254d88736248bf8834c98c29ad57ee@sentry.io/1304835',
        release: `patient-web@${this.envProxy.environment.version}`,
        environment: this.envProxy.environment.sentry.environment,
        integrations: [
          new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
          }),
        ],
        beforeSend(event: Sentry.Event, hint) {
          // Note: issue with double entries during http exceptions: https://github.com/getsentry/sentry-javascript/issues/2169
          // Note: issue with a second entry not being set correctly (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
          const isNonErrorException = event.exception.values[0].value.startsWith(
            'Non-Error exception captured'
          );
          if (isNonErrorException) {
            if (!event.extra.__serialized__) {
              return null;
            }
            const realErrMsg = (event.extra.__serialized__ as Record<string, any>).error
              ? (event.extra.__serialized__ as Record<string, any>).error.message
              : null;
            event.exception.values[0].value = realErrMsg;
            event.message = realErrMsg;
          }
          return event;
        },
      });
      this.initialised = true;
    }
  }

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    const sentryEnabled = this.envProxy.environment.production;
    if (this.initialised && sentryEnabled) {
      const extractedError = this.extractError(error) || 'Handled unknown error';

      // Capture handled exception and send it to Sentry.
      const eventId = Sentry.captureException(extractedError);

      // send error to other logging
      if (this.msgBus) {
        this.msgBus.trackEvent(
          AnalyticsEvent.error(
            'unhandled-exception',
            `Full error detailed in Sentry: https://sentry.io/organizations/pushdoctor/projects/clinicians-app/events/${eventId} - ${
              error.message || error.toString()
            }`
          )
        );
      }

      // When in development mode, log the error to console for immediate feedback.
      if (!this.envProxy.environment.production) {
        console.error(extractedError);
      }
    } else {
      throw error;
    }
  }
}
