import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WINDOW_PROVIDER } from '@pushdr/common/utils';
import { SentryErrorHandler } from './sentry-error-handler/sentry-error-handler';

@NgModule({
  imports: [CommonModule],
  providers: [WINDOW_PROVIDER, { provide: ErrorHandler, useClass: SentryErrorHandler }],
})
export class PatientappCommonConfigModule {}
