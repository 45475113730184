<div class="c-modal c-modal--is-visible">
  <div class="bg-orange-300 c-modal__wrap c-modal__wrap--40">
    <button type="button" class="absolute top-0 right-0 p-2" (click)="close()">
      <i class="text-3xl material-icons">cancel</i>
    </button>

    <h3 class="mb-8 text-error">Helpful tips to help you find your surgery</h3>

    <h5 class="mb-1">Surgery postcode</h5>
    <p class="mb-6">Are you entering your home postcode? Try your surgery’s postcode instead.</p>

    <h5 class="mb-1">Centre or Practice</h5>
    <p class="mb-6">
      Surgeries are sometimes referred to as ‘practice’ or ‘medical centre’. Double check you’re
      inputting the correct term for your surgery.
    </p>

    <h5 class="mb-1">Full postcode</h5>
    <p class="mb-6">
      Try using the full postcode and double checking all the letters and numbers are correct.
    </p>

    <h5 class="mb-1">Address</h5>
    <p class="mb-6">
      Are you searching by the street your surgery is on? Try searching by the postcode instead.
    </p>
    <hr class="mb-5" />
    <p class="mb-6">
      If you're still struggling to find your surgery, please get in touch by calling us on
      <a href="tel:03308084702" class="font-bold whitespace-nowrap">0330 808 4702</a>, where a
      member of our patient experience team will be on hand to help.
    </p>
  </div>
</div>
