import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppointmentSummary,
  ConsultationStatus,
  Customer,
  EncryptedUserStatus,
  GetAppointmentSummaryResult,
  GP,
  GPPartnershipType,
  GPShareStatus,
  Invite,
  InviteType,
  NamedEncryptedUser,
  PDServerError,
  SocialSignInKeys,
} from '@pushdr/common/types';
import { StorageService, TokenService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { ActionRequestService, InviteService } from '@pushdr/patientapp/common/services';
import {
  userHasValidSurgery,
  AccountProfileService,
  EncryptedUserService,
} from '@pushdr/patientapp/common/utils';
import { EMPTY, forkJoin, from, iif, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { FlowStateService, SelectedSurgeryType } from '../funnel-flow/flow-state.service';
import { GATE_PATIENT, GATE_VERIFICATION } from '../funnel-flow/flow-types';
import { FlowService } from '../funnel-flow/flow.service';
import { safeExternalRedirect, isExternalRedirect } from '@pushdr/common/utils';
import { private_registration } from '@pushdr/common/utils';
import { ModalService } from '@pushdr/common/overlay';

export interface PartialSurgery {
  PartnershipType: GPPartnershipType;
  ShareStatus: GPShareStatus;
  Id: number;
}

// TODO: remove the const?
export const PartialSurgeryFactory = (
  ShareStatus,
  Id = 1,
  PartnershipType = GPPartnershipType.NOT_A_PARTNER
): PartialSurgery => {
  return {
    ShareStatus,
    Id,
    PartnershipType,
  };
};

interface UserHas {
  completedProfile: boolean;
  verifiedMobile: boolean;
  pickedAValidSurgery: boolean;
  inProgressAppointment: boolean;
  actionRequest: boolean;
  updatedPartner: boolean;
  returnUrl: string;
  cancel: boolean;
}

@Injectable()
export class LoginService {
  userHas: UserHas;

  constructor(
    private api: ApiNHSPatientService,
    private oldApi: ApiPatientService,
    private flow: FlowService,
    private modal: ModalService,
    private state: FlowStateService,
    private token: TokenService,
    private store: StorageService,
    private userService: EncryptedUserService,
    private router: Router,
    private zone: NgZone,
    private accountProfile: AccountProfileService,
    private invite: InviteService,
    private route: ActivatedRoute,
    private envProxy: EnvironmentProxyService,
    private actionRequest: ActionRequestService
  ) {}

  signUpWithNhs(token: string): PromiseLike<NamedEncryptedUser> {
    this.modal.showLoader({ bottomText: 'Signing up with NHS' });
    return new Promise((res, rej) => {
      this.modal.showLoader({ bottomText: 'Signing up with Nhs details' });
      this.oldApi.general.nhsAuthentication(token).subscribe((user: NamedEncryptedUser) => {
        this.zone.run(() => {
          res(user);
        });
      }, this.errorHandler.bind(this));
    });
  }

  signInWithNhs(token: string) {
    return this.oldApi.general.nhsAuthentication(token).subscribe({
      next: (user: NamedEncryptedUser) => {
        this.zone.run(() => this.processUser(user, SocialSignInKeys.NHS));
      },
      error: () => {
        this.router.navigate(['/register', 'signup']);
        this.errorHandler.bind(this);
      },
    });
  }

  /**
   *
   * @param user current user (named because of social sign in response)
   * @param SocialSignIn which social sign in , @default '' e.g. not social
   * @param Password the password used if any to sign in
   */
  processUser(user: NamedEncryptedUser, SocialSignIn = '', Password = '') {
    this.flow.lock(['Set Password']);
    switch (user.EncryptedUser.Status) {
      case EncryptedUserStatus.NO_MOBILE:
        this.flow.unlock(['Set Mobile']);
        this.state.updatePatientModel({
          FirstName: user.SuggestedFirstName || '',
          LastName: user.SuggestedLastName || '',
          DOB:
            user.SuggestedUserDetails && user.SuggestedUserDetails.DateOfBirth
              ? user.SuggestedUserDetails.DateOfBirth
              : '',
        });
        this.state.updateRegistrationModel({
          SocialSignIn,
          Password,
          Email: user.EncryptedUser.Email,
          Mobile:
            user.SuggestedUserDetails && user.SuggestedUserDetails.Mobile
              ? user.SuggestedUserDetails.Mobile
              : '',
        });
        this.modal.close();
        this.flow.next();
        break;
      case EncryptedUserStatus.MOBILE_NOT_VERIFIED:
      case EncryptedUserStatus.TOKEN_REQUIRED:
        this.state.updateRegistrationModel({ SocialSignIn, Password });
        this.processStateFromUser(user, true);
        break;
      case EncryptedUserStatus.BLOCKED: // User blocked
        this.modal.acknowledge(
          'Blocked',
          'Your account is currently blocked, contact customer services to unblock'
        );
        break;
    }
  }

  /**
   * The common error handler which handles all login/sign in similarly
   * @param error server error fed back from service layer
   */
  errorHandler(error: PDServerError) {
    if (error.message === 'popup_closed_by_user') {
      this.modal.close();
    } else if (typeof error.status === 'undefined') {
      this.modal.error(error.original || error.message);
    } else {
      this.modal.error(this.api.errorMessages.authentication.login(error).message);
    }
  }

  /**
   * Common processing function for signing in. All roads lead here to establish the current users progress in
   * the flow
   * @param user current user signing in
   * @param useUserStateToNavigateInFunnel should this sign in attempt to trigger the next flow step automatically @default true
   */
  processStateFromUser(user: NamedEncryptedUser, useUserStateToNavigateInFunnel = true) {
    this.modal.showLoader({ bottomText: 'Fetching details' });
    this.userService.user = user.EncryptedUser;
    this.resetUserHasState(user);
    of({})
      .pipe(
        switchMap(() => this.resolveCustomer(user)),
        switchMap(() => this.tryToResolveBearerToken()),
        switchMap(() =>
          forkJoin([this.resolveUsersConsultationStatus(), this.resolveAReturnURL()])
        ),
        switchMap(() => this.resolveClaimedInvites()),
        switchMap(() => this.resolveActionRequests()),
        switchMap(() => {
          const prefunnelSurgerySelectionInvite =
            this.checkForPreselectedSurgeryAndCreateSyntheticInvite();
          if (prefunnelSurgerySelectionInvite) {
            return this.resolveTheGPDetails().pipe(
              switchMap(gp =>
                this.updateGPIfDifferentFromInvites(gp, prefunnelSurgerySelectionInvite as Invite)
              )
            );
          } else {
            return this.resolveTheGPDetails();
          }
        }),
        switchMap(() => this.resolveCurrentAppointment()),
        switchMap(() => this.flow.config$),
        take(1)
      )
      .subscribe({
        next: () => console.log('(｡◕‿◕｡)⊃━☆ﾟ. * ･ ｡ﾟ - user state processed!'),
        error: () => this.enterOrSkipFunnel(useUserStateToNavigateInFunnel),
        complete: () => this.enterOrSkipFunnel(useUserStateToNavigateInFunnel),
      });
  }

  private checkForPreselectedSurgeryAndCreateSyntheticInvite() {
    const selectedSurgery = this.state.getSelectedSurgeryStore();
    // Replaces optimizely 'private_registration' flag
    // TODO: Add proper config for 'private_registration' flag
    const privateRegistrationConfig = private_registration;

    if (
      selectedSurgery.surgeryType === SelectedSurgeryType.TBC ||
      (!privateRegistrationConfig.enabled &&
        selectedSurgery.surgeryType !== SelectedSurgeryType.PARTNERED)
    )
      return null;

    this.state.clearSelectedSurgeryStore();
    return { meta: { gp: { odscode: selectedSurgery.odsCode } } };
  }

  private enterOrSkipFunnel(useUserStateToNavigateInFunnel) {
    if (this.userHas.cancel) return;
    if (this.userNeedsToFinishTheFunnel()) {
      this.flow.setPageLockTo(['Verify Mobile'], !!this.userHas.verifiedMobile);
      this.flow.setPageLockTo(['Select Surgery'], this.userHas.pickedAValidSurgery);
      this.flow.setPageLockTo(['Set Mobile'], !!this.state.registration.Mobile);
      if (useUserStateToNavigateInFunnel) {
        this.accountProfile.resolve().then(() => {
          this.jumpToStartingPoint();
        });
      }
    } else {
      this.invite.spendInvite(InviteType.REGISTRATION).subscribe(
        () => {
          this.userHas.updatedPartner = true;
          console.log('Registration invite spent...･｡ﾟ[̲̅$̲̅(̲̅ ͡° ͜ʖ ͡°̲̅)̲̅$̲̅]｡ﾟ.*');
        },
        (err: PDServerError) => {
          switch (err.status) {
            case 409:
              this.modal
                .acknowledge(err.message, 'I understand')
                .pipe(take(1))
                .subscribe(() => this.exitFunnel());
              break;
            default:
              this.modal.error(err.message); //basic errors for now
          }
        },
        () => {
          this.exitFunnel();
        }
      );
    }
  }

  private updateGPIfDifferentFromInvites(gp: GP, invite: Invite) {
    if (invite.meta.gp && (!gp || invite.meta.gp.odscode !== gp.OdsCode)) {
      return this.updateGPFromInviteMeta(invite).pipe(
        tap(() => (this.userHas.updatedPartner = true)),
        switchMap(() => {
          return this.resolveTheGPDetails();
        })
      );
    }

    return of(null);
  }

  private resolveClaimedInvites() {
    return this.invite
      .claimInvite()
      .pipe(
        catchError((err: PDServerError) =>
          this.modal.acknowledge('', err.message).pipe(switchMap(() => throwError(() => err)))
        )
      );
  }

  private resolveActionRequests() {
    return this.actionRequest
      .getActionRequest$()
      .pipe(tap(actionReq => (this.userHas.actionRequest = !!actionReq)));
  }

  private exitFunnel() {
    this.accountProfile.resolve().then(() => {
      this.clear(['patient', 'registration']);
      this.router.navigate(['/terms'], {
        queryParams: {
          checking: 1,
          redirect: this.exitUrl(),
        },
      });
    });
  }

  private userNeedsToFinishTheFunnel() {
    return !(
      this.userHas.completedProfile &&
      this.userHas.pickedAValidSurgery &&
      this.userHas.verifiedMobile
    );
  }

  private jumpToStartingPoint() {
    if (!this.state.registration.Mobile) {
      this.flow.next();
    } else if (!this.userHas.completedProfile) {
      this.clear(['registration']);
      this.flow.gotoGate(this.flow.getGate(GATE_PATIENT));
    } else {
      this.clear(['registration']);
      this.flow.gotoGate(this.flow.getGate(GATE_VERIFICATION));
    }
  }

  private exitUrl() {
    if (this.userHas.updatedPartner) {
      return '/verification/success/partner';
    } else if (this.userHas.returnUrl) {
      return this.userHas.returnUrl;
    } else if (this.userHas.inProgressAppointment) {
      return this.envProxy.environment.patient.account.url;
    } else if (this.accountProfile.isPrivate) {
      return '/booking/private';
    } else {
      return '/booking/nhs';
    }
  }

  private updateGPFromInviteMeta(invite: Invite) {
    if (invite && invite.meta.gp) {
      if (this.userHas.verifiedMobile) {
        return this.oldApi.account.updateGP(
          invite.meta.gp.id.toString(),
          GPShareStatus.DO_NOT_SHARE
        );
      }
      return this.api.customer.setSurgery(invite.meta.gp.odscode);
    } else {
      return of(null);
    }
  }

  private resolveCustomer(user): Observable<Customer> {
    return this.api.customer.getCustomer().pipe(
      tap(patient => {
        patient.GpShare = GPShareStatus.DO_NOT_SHARE;
        return this.state.parseLogin(user, patient);
      })
    );
  }

  private resolveTheGPDetails(): Observable<GP> {
    return from(this.accountProfile.resolve()).pipe(
      mergeMap(() => this.api.customer.getGPDetails()),
      catchError(error => of(null)),
      switchMap(gp => {
        if (!gp && this.userHas.verifiedMobile) {
          return this.oldApi.account.getGP();
        } else {
          return of(gp);
        }
      }),
      tap((gp: GP) => {
        this.userHas.pickedAValidSurgery = this.didUserPickAValidSurgery(gp);
      })
    );
  }

  private resolveCurrentAppointment(): Observable<AppointmentSummary[] | null> {
    const inProgressAppointment$ = () =>
      this.oldApi.booking.getAppointmentSummary().pipe(
        map(appointments =>
          appointments.filter(appt => {
            const status = appt.strAppointmentStatus.toLocaleLowerCase();
            return status === 'in progress' || status === 'scheduled';
          })
        ),
        tap(
          inProgress => (this.userHas.inProgressAppointment = inProgress && inProgress.length > 0)
        )
      );

    return iif(() => this.userHas.verifiedMobile, inProgressAppointment$(), of(null));
  }

  private tryToResolveBearerToken(): Observable<string> {
    // get token, not called unless iif resolves
    const generateBearer$ = this.api.authentication.generateToken().pipe(
      filter(bearerToken => !!bearerToken),
      tap(bearerToken => this.token.set(bearerToken)),
      catchError((err: PDServerError) => {
        this.modal.error(err.message);
        this.userHas.cancel = true;
        this.userHas.verifiedMobile = false;
        return EMPTY;
      })
    );

    return iif(() => this.userHas.verifiedMobile, generateBearer$, of(null));
  }

  private resolveAReturnURL() {
    return this.route.queryParams.pipe(
      take(1),
      map(params => {
        const returnUrl = params.returnUrl || '';
        this.userHas.returnUrl =
          safeExternalRedirect(returnUrl) || !isExternalRedirect(returnUrl) ? returnUrl : '';
        return this.userHas.returnUrl;
      })
    );
  }

  private resolveUsersConsultationStatus(): Observable<ConsultationStatus> {
    return this.api.customer
      .consultationStatus()
      .pipe(tap(cs => (this.userHas.completedProfile = cs.ProfileComplete)));
  }

  private resetUserHasState(user) {
    // complete stat object
    this.userHas = {
      completedProfile: false,
      verifiedMobile: user.EncryptedUser.Status === EncryptedUserStatus.TOKEN_REQUIRED,
      pickedAValidSurgery: false,
      inProgressAppointment: false,
      updatedPartner: false,
      returnUrl: '',
      actionRequest: false,
      cancel: false,
    };
  }

  private clear(sessions: string[] | string) {
    sessions = Array.isArray(sessions) ? sessions : [sessions];
    sessions.forEach(s => this.store.deleteSessionStorage(s));
  }

  didUserPickAValidSurgery(pickedSurgery: PartialSurgery) {
    if (private_registration.enabled) {
      return !!pickedSurgery && pickedSurgery.Id > 0;
    }

    return (
      !!pickedSurgery &&
      userHasValidSurgery(
        pickedSurgery.Id,
        pickedSurgery.ShareStatus,
        pickedSurgery.PartnershipType,
        this.accountProfile.isNHS
      )
    );
  }
}
