<ng-container *ngIf="redirecting; else nhsRegistrationPage">
  <pushdr-spinner-loader
    title="NHS login"
    message="You are now being redirected to the NHS login website."></pushdr-spinner-loader>
</ng-container>

<ng-template #nhsRegistrationPage>
  <div class="c-form-part" trackView="nhs verification info">
    <section class="u-mb24@xs">
      <h2 pdFocus="true">Verifying who you are</h2>

      <p>
        The first time you set up an NHS login you will need to
        <strong>verify who you are</strong> and show one of these forms of ID:
      </p>

      <ul class="u-mt8@xs verification-list">
        <li>Passport</li>
        <li>UK driving license</li>
        <li>European driving license</li>
        <li>European national identity card</li>
      </ul>
    </section>

    <section class="u-mb24@xs">
      <div class="c-notification c-notification--warning">
        <span>
          Please note it may take up to <strong>2 hours</strong> to verify a new NHS login.
        </span>
      </div>
    </section>

    <section>
      <img
        title="Continue with NHS login"
        alt="continue-with-nhs-login"
        src="/assets/nhs-assets/nhs_continue_with_original.svg"
        class="btn-nhs u-mb16@xs"
        tabindex="0"
        (click)="signUpWithNHS()" />
      <button class="c-btn go-back-to-signup" (click)="goBackToSignUp()">
        I want to register with email
      </button>
    </section>
  </div>
</ng-template>
