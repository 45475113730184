import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsComponent } from '../common/terms/terms.component';
import { SignupStartedGuard } from './sign-up-started.guard';
import { MobileComponent } from './mobile/mobile.component';
import { ForgotPasswordComponent } from './password/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './password/reset-password/reset-password.component';
import { PasswordComponent } from './password/set-password/password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MaintenanceComponent } from '../common/maintenance/maintenance.component';
import { IsNotFullyAuthedGuard } from './is-not-fully-authed.guard';
import { PreSurgerySelectorComponent } from '../common/surgery/pre-surgery-selector/pre-surgery-selector.component';
import { SurgerySelectedComponent } from '../common/surgery/surgery-selected/surgery-selected.component';
import { SurgeryNotifyComponent } from '../common/surgery/surgery-notify/surgery-notify.component';
import { NhsAuthorisationComponent } from './nhs-authorisation/nhs-authorisation.component';
import { SurgerySelectedGuard } from './surgery-selected-guard';
import { WhatIsNHSLoginComponent } from './what-is-nhs-login/what-is-nhs-login.component';
import { NhsVerificationInfoComponent } from './nhs-verification-info/nhs-verification-info.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/register/signup',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: NhsAuthorisationComponent,
  },
  {
    path: 'surgery-preselect',
    component: PreSurgerySelectorComponent,
    canActivate: [IsNotFullyAuthedGuard],
  },
  {
    path: 'surgery-preselect/selected',
    component: SurgerySelectedComponent,
    canActivate: [IsNotFullyAuthedGuard],
  },
  {
    path: 'surgery-preselect/notify',
    component: SurgeryNotifyComponent,
    canActivate: [IsNotFullyAuthedGuard],
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'what-is-nhs-login',
    component: WhatIsNHSLoginComponent,
  },
  {
    path: 'nhs-verification',
    component: NhsVerificationInfoComponent,
  },
  {
    path: 'register',
    redirectTo: '/register/signup',
    pathMatch: 'full',
  },
  {
    path: 'register',
    children: [
      {
        path: 'signup',
        component: SignUpComponent,
        canActivate: [IsNotFullyAuthedGuard, SurgerySelectedGuard],
      },
      {
        path: 'login',
        component: SignInComponent,
        canActivate: [IsNotFullyAuthedGuard],
      },
      {
        path: 'getcode',
        component: ForgotPasswordComponent,
        canActivate: [],
      },
      {
        path: 'resetpassword',
        component: ResetPasswordComponent,
        canActivate: [],
      },
      {
        path: 'mobile',
        component: MobileComponent,
        canActivate: [SignupStartedGuard],
      },
      {
        path: 'password',
        component: PasswordComponent,
        canActivate: [SignupStartedGuard],
      },
      {
        path: 'terms',
        canActivate: [SignupStartedGuard],
        component: TermsComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/register/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [SignupStartedGuard, IsNotFullyAuthedGuard, SurgerySelectedGuard],
})
export class RegisterRoutingModule {}
